// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-game-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-math-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/math.js" /* webpackChunkName: "component---src-pages-math-js" */),
  "component---src-pages-reality-js": () => import("/Users/chenyangtie/Documents/nodejs/gats1/awsmath24/src/pages/reality.js" /* webpackChunkName: "component---src-pages-reality-js" */)
}

